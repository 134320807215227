.Page{
    min-height: 70vh;
    margin: auto;
}

h1,h2, h3{
    margin-top: 10px;
    margin-bottom: 10px;
}

pre {
    background: #eee;
    padding: 1rem;
    overflow: auto;
    border-radius: 3px;
    max-width: 80ch;
  }

  pre code {
      text-align: left;
      white-space: pre;
      word-spacing: normal;
      word-break: normal;
      word-wrap: normal;
      line-height: 1.5;
  }
