.reactMarkDown {
    
}
  
.reactMarkDown ul {
    margin-top: 1em;
    margin-bottom: 1em;
    list-style: disc outside none;
}

.reactMarkDown ul li {
    margin-left: 2em;
    display: list-item;
    text-align: -webkit-match-parent;
}

.reactMarkDown ol li {
    margin-left: 2em;
    display: list-item;
    text-align: -webkit-match-parent;
}

.reactMarkDown p { 
    font-size: 14px;
    font-weight: 400; 
    white-space: pre-wrap;
}